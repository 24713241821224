import React from 'react'
import { Link } from 'gatsby'

function LinkTo({uid, text}) {
    console.log('linkto', text)
  return (
    <Link to={`/${uid}`}>
      <p>{text}</p>
    </Link>
  )
}

export default LinkTo