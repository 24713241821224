import React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { repositoryConfigs } from '../utils/prismicPreviews'
import LayoutAnleitungen from '../components/layout/LayoutAnleitungen'
import History from '../components/history'
import SEO from '../components/seo'
import { Row, Col } from 'react-bootstrap'

const GroupeTemplate = ({ data }) => {
  console.log('data', data)
  const groupeData = data.prismicGroupe.data
  console.log('groupe data', groupeData)
  const members = groupeData.members1
  console.log('mermbers', members)

  // does not work???
  /*   const membersList = members.map((member) => {
    const memberData = member.member.document.data
    console.log('memberData', memberData)
    return (
      <li key={member.index} style={{ color: 'red' }}>
        {memberData.abbr}
      </li>
    )
  }) */

  const membersTable = (
    <Col>
      <h4>Telnehmer*innen</h4>
      <table>
        <tr>
          <th>Abkürzung</th>
          <th title="wenig (1) - sehr (5)">Fitness</th>
          <th title="zart (1) - kräftig (5)">Kraft</th>
          <th title="allmählich (1) - plötzlich (5)">Zeit</th>
          <th title="flexibel (1) - direkt (5)">Raum</th>
          <th title="gebunden (1) - frei (5)">Qualität</th>
        </tr>
        {members.map((member) => {
          let memberData = []
          member.member.document
            ? (memberData = member.member.document.data)
            : null
          console.log('memberData', memberData)
          let force = []
          let time = []
          let space = []
          let m_quality = []

          memberData.m_analysis
            ? memberData.m_analysis.map((analys) => {
                force.push(analys.force)
                time.push(analys.time)
                space.push(analys.space)
                m_quality.push(analys.m_quality)
              })
            : null

          return (
            <tr key={memberData.index} id="rows">
              <td>{memberData.abbr}</td>
              <td>{memberData.fitness}</td>
              <td>{force}</td>
              <td>{time}</td>
              <td>{space}</td>
              <td>{m_quality}</td>
            </tr>
          )
        })}
      </table>
    </Col>
  )

  return (
    <LayoutAnleitungen>
      <SEO index="noindex" />
      <Row style={{ color: 'purple', backgroundColor: 'gray' }}>
        <h1>Gruppe {groupeData.group_no}</h1>
        {groupeData.show_members ? membersTable : null}
        <hr />
        <p>Sprache: {groupeData.language}</p>
      </Row>
      <hr />
      <History
        groupeNo={groupeData.group_no}
        showMembers={groupeData.show_members}
      />
    </LayoutAnleitungen>
  )
}

export const query = graphql`
  query groupeQuery($id: String, $lang: String) {
    prismicGroupe(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      url
      uid
      type
      id
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        show_members
        group_no
        members
        language
        members1 {
          member {
            document {
              ... on PrismicMember {
                id
                data {
                  abbr
                  history {
                    date(locale: "")
                    rich_text {
                      raw
                    }
                  }
                  fitness
                  m_analysis {
                    bodypart
                    force
                    m_quality
                    moment
                    space
                    time
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
export default withPrismicPreview(GroupeTemplate, repositoryConfigs)
