import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import LinkTo from './LinkTo'
import { RichText } from 'prismic-reactjs'
import AnleitungsList from './AnleitungsList'
import moment from 'moment'

function History({ groupeNo, showMembers }) {

  const data = useStaticQuery(graphql`
    query {
      allPrismicHistory {
        nodes {
          data {
            rich_comments_1 {
              raw
            }
            time
            group_no {
              document {
                ... on PrismicGroupe {
                  data {
                    group_no
                    show_members
                    members1 {
                      member {
                        document {
                          ... on PrismicMember {
                            data {
                              abbr
                              history {
                                rich_text {
                                  raw
                                }
                                date
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            anleitungen_1 {
              anleitung {
                uid
                document {
                  ... on PrismicAnleitung {
                    id
                    data {
                      steps {
                        active
                        duration_mm
                      }
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const historyData = data.allPrismicHistory.nodes

  // Sort arr by date
  historyData.sort(function (a, b) {
    return new Date(b.data.time) - new Date(a.data.time)
  })

  const historyList = historyData.map((list) => {
    /*     const membersCourse = list.data.group_no.document.data.members1
    
    // add history date to membersCourse
    // membersCourse.historyDate = list.data.date
    for (let index = 0; index < membersCourse.length; index++) {
      const element = membersCourse[index];
      element.historyDate = list.data.date
    } */

    // calc total duration
    let totalDuration1 = 0

    /*     const totalDurationCalc = (anleitungen) => {

      anleitungen.map((anleitung) => {
        if (!anleitung.anleitung || anleitung.anleitung.document === null)
          return null
        return anleitung.anleitung.document.data.steps.map((steps) => {
          if (steps.active) {
            return (totalDuration1 += steps.duration_mm)
          } else return null
        })
      })
    } */

    /*     const membersCourseList = (
      <>
        <h4>Verlauf:</h4>
        <ul>
          {membersCourse.map((memberCourse) => {
            return (
              <li key={memberCourse.index}>
                {memberCourse.member.document.data.abbr}:{memberCourse.member.document.data.history.map((item) => {

                  if (memberCourse.historyDate === item.date) {
                    return item.rich_text ? (
                      <RichText render={item.rich_text.raw} />
                    ) : null
                  }

                })}
              </li>
            )
          })}
        </ul>
      </>
    ) */
    // if (!list.data.group_no.document || list.data.group_no.document.data.group_no !== groupeNo) return null
    if (!list.data.group_no.document) return null

    // totalDurationCalc(list.data.anleitungen_1)

    let totalDuration2 = 0

    // the second does not work with that function
    // totalDurationCalc(list.data.anleitungen_1, totalDuration)
    /*     {
      list.data.anleitungen_2.map((anleitung) => {
        if (!anleitung.anleitung || anleitung.anleitung.document === null)
          return null
        list.data.anleitungen_1.hasAnleitung = true

        return (
          anleitung.anleitung.document.data.steps.map((steps) => {
            if (steps.active)
              return (
                (totalDuration2 += steps.duration_mm)
              )
          })
        )
      })
    } */

    if (
      list.data !== undefined &&
      list.data.group_no !== undefined &&
      list.data.group_no.document?.data?.group_no === groupeNo
    )
      /*
     same as:
     if (
  list.data !== undefined && 
  list.data.group_no !== undefined &&
  list.data.group_no.document !== undefined &&
  list.data.group_no.document.data !== undefined &&
  list.data.group_no.document.data.group_no === groupeNo
)
    */
      return (
        <>
          {/* {durationCalc(list.data.anleitungen_1, totalDuration)} */}
          {list.data.time ? (
            <h3 key={list.data.time}>
              {moment(list.data.time).format('D. MMMM YY HH:mm')}
            </h3>
          ) : null}
          <h4>
            1.Stunde <span>({totalDuration1}`)</span>
          </h4>
          {list.data.anleitungen_1.hasAnleitung ? <h5>Anleitungen</h5> : null}
          <AnleitungsList
            anleitungen={list.data.anleitungen_1}
            showStepsList={false}
          />

          {/*
        {list.data.lesson_1.document ? (
          <>
            <h5>Stunde</h5>
            <LinkTo
              uid={list.data.lesson_1.uid}
              text={list.data.lesson_1.document.data.title.text}
            />
          </>
        ) : null}*/}

          <>
            <h4>Kommentare</h4>
            <RichText render={list.data.rich_comments_1.raw} />
          </>
          {showMembers ? (
            <>
              <h4>Verlauf Mitlieder</h4>
              {/* <RichText render={list.data.members_course_1.raw} /> */}
            </>
          ) : null}

          {/* {showMembers ? membersCourseList : null} */}
          {/* 
        <h4>
          2. Stunde<span>({totalDuration2}`)</span>
        </h4>
        {list.data.anleitungen_2.hasAnleitungen ? <h5>Anleitungen</h5> : null}
        <AnleitungsList
          anleitungen={list.data.anleitungen_2}
          showStepsList={false}
        />
        {list.data.lesson_2.document ? (
          <>
            <h5>Stunde</h5>
            <LinkTo
              uid={list.data.lesson_2.uid}
              text={list.data.lesson_2.document.data.title.text}
            />
          </>
        ) : null}

        {list.data.rich_comments_2.raw.length > 0 ? (
          <>
            <h4>Kommentare</h4>
            <RichText render={list.data.rich_comments_2.raw} />
          </>
        ) : null}
        {showMembers ? (
          <>
            <h4>Verlauf Mitlieder</h4>
            <RichText render={list.data.members_course_2.raw} />
          </>
        ) : null} */}
        </>
      )
  })

  return (
    <div>
      <h2>Verlauf</h2>
      <div>{historyList}</div>
    </div>
  )
}

export default History
